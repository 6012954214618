import Vue from 'vue/dist/vue.esm'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)


new Vue({
  el: '#featured-trials',
  props: {},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 1,
        spaceBetween: 10,
        breakpoints: {
          1100: {
            slidesPerView: 2,
            spaceBetween: 30,
          }
        },
        observer: true,
        observeParents: true,
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.featuredTrials.$swiper
    }
  },
  mounted() {
    Vue.nextTick(() => {
      this.swiper.update()
    })
  }
})
